<template>

  <div class="service_form_details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="5"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              Invoice Details - {{ invoiceDetail.trxNo }}
            </h1>
            <p class="log_info">
              Invoice created by {{ getCreatedBy() }} on {{ getCreatedAt() }}<br>Last updated on {{ getUpdatedAt() }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right mb-1"
        md="7"
        lg="7"
        cols="12"
      >
        <div class="user_block no-min-width file_payment_type user__block-margin-adjust">
          <div class="text-right ml-auto vendor-detail-more-icon file_detail_tab_end">
            <b-dropdown
              variant="flat-primary"
              no-caret
              class=""
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-if="canViewThisAction('register-payment', 'Invoice')"
                :to="{ name: 'customers-register-payment', query: { fileID: invoiceDetail.fileID || 0 } }"
              >
                <feather-icon icon="DollarSignIcon" />
                <span class="align-middle ml-50">Register Payment</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canViewThisAction('download', 'Invoice')"
                @click="downloadInvoice()"
              >
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">Download Invoice</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canViewThisAction('send-email', 'Invoice')"
                @click="openEmailStatementModal()"
              >
                <feather-icon icon="MailIcon" />
                <span class="align-middle ml-50">Email Invoice to Customer</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="user_block pr_stage_type user__block-margin-adjust">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Invoice Status</h6>
            <h5
              class="text-capitalize"
              :class="`text-${resolveInvoiceStatusVariant(invoiceDetail.paymentStatus)}`"
            >
              {{ invoiceDetail.paymentStatus }}
            </h5>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-row class="match-height">
      <b-col md="6">
        <b-card
          title="Invoice & Customer Details"
          class="no-padding-td"
        >
          <b-table-simple
            borderless
            responsive
            class="particulars_of_deceased d-none d-md-block"
          >
            <b-tbody>
              <b-tr>
                <b-th width="230px">
                  Invoice Date
                </b-th>
                <b-td>{{ invoiceDetail.trxDate ? dateFormat(invoiceDetail.trxDate) : '-' }}</b-td>
              </b-tr>
              <b-tr>
                <b-th width="230px">
                  PO No.
                </b-th>
                <b-td>{{ (invoiceDetail.nvsFileID && invoiceDetail.nvsFileID.poNo) ? invoiceDetail.nvsFileID.poNo : '-' }}</b-td>
              </b-tr>
              <b-tr>
                <b-th width="230px">
                  Remarks
                </b-th>
                <b-td>{{ invoiceDetail.remarks ? invoiceDetail.remarks : '-' }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <hr>
          <b-table-simple
            borderless
            responsive
            class="particulars_of_deceased d-none d-md-block"
          >
            <b-tbody>
              <b-tr>
                <b-th width="230px">
                  Customer name
                </b-th>
                <b-td class="text-uppercase">
                  {{ (invoiceDetail.purchasers && invoiceDetail.purchasers.length) ? invoiceDetail.purchasers[0].name : '-' }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th width="230px">
                  NRIC No.
                </b-th>
                <b-td>
                  {{ (invoiceDetail.purchasers && invoiceDetail.purchasers.length) ? invoiceDetail.purchasers[0].nric.replace(/.(?=.{4,}$)/g, '*') : '-' }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th width="230px">
                  Mobile No.
                </b-th>
                <b-td>{{ (invoiceDetail.purchasers && invoiceDetail.purchasers.length) ? invoiceDetail.purchasers[0].contact : '-' }}</b-td>
              </b-tr>
              <b-tr>
                <b-th width="230px">
                  Email address
                </b-th>
                <b-td>{{ (invoiceDetail.purchasers && invoiceDetail.purchasers.length && invoiceDetail.purchasers[0].customer) ? invoiceDetail.purchasers[0].customer.email : '-' }}</b-td>
              </b-tr>
              <b-tr>
                <b-th width="230px">
                  Address
                </b-th>
                <b-td>{{ (invoiceDetail.purchasers && invoiceDetail.purchasers.length && invoiceDetail.purchasers[0].customer) ? getResidentialAddress(invoiceDetail.purchasers[0]) : '-' }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card
          v-if="invoiceDetail.nvsFileID"
          title="File Details"
          class="no-padding-td"
        >
          <b-table-simple
            borderless
            responsive
            class="particulars_of_deceased d-none d-md-block"
          >
            <b-tbody>
              <b-tr>
                <b-th width="230px">
                  File No.
                </b-th>
                <b-td>
                  <b-button
                    v-if="canViewThisAction('view', 'File')"
                    variant="link"
                    class="p-0"
                    :to="{ name: 'customers-files-show', params: { id: invoiceDetail.fileID || 0 } }"
                  >
                    {{ invoiceDetail.fileNo }}
                  </b-button>
                  <span v-else>{{ invoiceDetail.fileNo }}</span>
                  (Status: <span :class="`text-${resolveFileStatusVariant(invoiceDetail.nvsFileID.nvsFileStatus)} weight-600`">{{ invoiceDetail.nvsFileID.nvsFileStatus ? invoiceDetail.nvsFileID.nvsFileStatus : '-' }}</span>)
                </b-td>
              </b-tr>
              <b-tr>
                <b-th width="230px">
                  Lot No.
                </b-th>
                <b-td>{{ invoiceDetail.lotLocation ? invoiceDetail.lotLocation : '-' }}</b-td>
              </b-tr>
              <b-tr>
                <b-th width="230px">
                  Lot Type
                </b-th>
                <b-td>{{ (invoiceDetail.nvsFileID && invoiceDetail.nvsFileID.lotType) ? invoiceDetail.nvsFileID.lotType : '-' }}</b-td>
              </b-tr>
              <b-tr>
                <b-th width="230px">
                  Product Description
                </b-th>
                <b-td>{{ invoiceDetail.nvsFileType ? invoiceDetail.nvsFileType : '-' }}</b-td>
              </b-tr>
              <b-tr>
                <b-th width="230px">
                  Remarks
                </b-th>
                <b-td>{{ invoiceDetail.nvsFileID.remarks ? invoiceDetail.nvsFileID.remarks : '-' }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>

    <b-card
      title=""
      class="mb-3 mt-1 p-2"
    >
      <b-card-title class="d-flex">
        <span>Invoice Items</span>
        <b-button
          v-if="canViewThisAction('register-payment', 'Invoice')"
          variant="link"
          class="ml-auto"
          :to="{ name: 'customers-register-payment', query: { fileID: invoiceDetail.fileID || 0 } }"
        >
          Register Payment
        </b-button>
      </b-card-title>
      <b-card-text>
        <InvoiceItems
          :billings="invoiceDetail.billings"
          :invoice="invoiceDetail"
        />
      </b-card-text>

      <b-card-text>
        <Receipts
          :receipts="receipts"
          :invoice="invoiceDetail"
        />
      </b-card-text>
    </b-card>

    <b-card
      title="Attachments"
      class="mb-3 mt-3 p-2"
    >
      <input
        ref="hiddenAllocationFileInput"
        type="file"
        multiple
        capture
        accept="image/jpeg, image/png, application/pdf"
        class="d-none"
        @change="setAttachment"
      >
      <b-row v-if="invoiceDetail.attachments && invoiceDetail.attachments.length">
        <!-- Content Left -->
        <b-col
          v-for="(file, key) in invoiceDetail.attachments"
          :key="key"
          class="content-header-left mb-2"
          cols="12"
          md="4"
        >
          <div
            class="attachment-item"
          >
            <b-img
              v-if="file.type.includes('image')"
              left
              height="40"
              :src="require('@/assets/images/admin/doc_jpg.png')"
              alt="Left image"
            />
            <b-img
              v-if="file.type.includes('application')"
              left
              height="40"
              :src="require('@/assets/images/admin/doc_file.png')"
              alt="Left image"
            />
            <strong>
              <b-link
                :href="file ? file.data : ''"
                target="_blank"
              >
                {{ file ? file.name : '' }}
              </b-link>
            </strong>
            <span>{{ file ? `${file.size}mb` : '' }}</span>
            <span class="remove__doc">
              <b-button
                v-if="canViewThisAction('update', 'Invoice')"
                variant="flat-primary"
                @click="removeAttachment(file.name, file.data)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                  size="18"
                />
              </b-button>
            </span>
          </div>
          <div class="doc__desc new_design">
            <span v-if="file.description">
              {{ file.description }}
            </span>
            <span v-else>
              <button
                v-if="canViewThisAction('update', 'Invoice')"
                @click="updateDescription(key)"
              >
                Add a description
              </button>
            </span>
            <span
              v-if="file.description"
              class="edit__desc"
            >
              <b-button
                v-if="canViewThisAction('update', 'Invoice')"
                variant="flat-primary"
                @click="updateDescription(key)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                  size="18"
                />
              </b-button>
            </span>
          </div>
        </b-col>
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="4"
        >
          <b-button
            v-if="canViewThisAction('update', 'Invoice')"
            variant="link"
            class="mr-1 md-mb-1"
            @click="$refs.hiddenAllocationFileInput.click()"
          >
            <feather-icon
              icon="UploadIcon"
              size="20"
              class="mr-50"
            />
            <span
              class="align-middle"
              style="font-weight: 500; font-size: 14px;"
            >
              Upload
            </span>
          </b-button>
          <b-button
            v-if="attachments.length"
            variant="success"
            class="mr-1 md-mb-1"
            @click="uploadFile"
          >
            Save File
          </b-button>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <div class="empty_block">
            <h4>Nothing in here yet</h4>
            <b-button
              v-if="canViewThisAction('update', 'Invoice')"
              variant="link"
              class=""
              @click="$refs.hiddenAllocationFileInput.click()"
            >
              <feather-icon
                icon="UploadIcon"
                size="20"
                class="mr-50"
              />
              <span
                class="align-middle"
                style="font-weight: 500; font-size: 14px;"
              >
                Upload
              </span>
            </b-button>
            <b-button
              v-if="attachments.length"
              variant="success"
              class="mr-1 md-mb-1"
              @click="uploadFile"
            >
              Save File
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="canViewThisAction('read', 'ServiceRequest')"
      title="Related Service Requests"
      class="mb-3 mt-3 p-2"
    >
      <b-card-text>
        <ServiceRequests :service-requests="[]" />
      </b-card-text>
    </b-card>

    <b-card
      class="mb-3 mt-3 p-2"
    >
      <b-row
        class="content-header"
      >
        <!-- Content Left -->
        <b-col
          class="content-header-left pb-2"
          cols="12"
          md="12"
        >
          <h4>
            <feather-icon
              icon="ListIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Activity Logs</span>
          </h4>
        </b-col>
      </b-row>
      <app-timeline>
        <!-- FINANCIAL REPORT -->
        <app-timeline-item
          v-for="(opt, key) in invoiceDetailActivityLogs"
          :key="key"
          :variant="getVariant(key)"
        >

          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ opt.title }}</h6>
            <small class="text-muted">{{ dateFormatWithTime(opt.time) }}</small>
          </div>
          <b-button
            v-if="opt.activityDetails && opt.activityDetails.length"
            v-b-toggle="'report-list-with-icon-' + key"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="outline-primary"
          >
            Details
            <feather-icon
              icon="ChevronUpIcon"
              class="ml-50 when-open"
              size="16"
            />
            <feather-icon
              icon="ChevronDownIcon"
              class="ml-50 when-closed"
              size="16"
            />
          </b-button>
          <b-collapse
            v-if="opt.activityDetails && opt.activityDetails.length"
            :id="'report-list-with-icon-' + key"
          >
            <b-table-simple
              borderless
              responsive
            >
              <b-tbody>
                <b-tr
                  v-for="(activityDetail, activitykey) in opt.activityDetails"
                  :key="activitykey"
                >
                  <b-th style="width: 300px; max-width: 300px;">
                    {{ activityDetail.title }}
                  </b-th>
                  <b-td>{{ activityDetail.oldValue }}</b-td>
                  <b-td>{{ activityDetail.newValue }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-collapse>
        </app-timeline-item>
      </app-timeline>
    </b-card>

    <b-modal
      id="modal-attachment-description"
      size="lg"
      title="Attachment Description"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="saveDescription"
      @cancel="closeDescriptionModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="description"
            placeholder="E.g. Payment Schedule"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!description || attachmentKey === ''"
          @click="ok()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-invoices-remarks"
      size="sm"
      title="Edit Remarks"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      footer-class="iu-edit-modal-footer"
      @ok="saveInvoiceRemarks"
      @cancel="closeInvoiceRemarksModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="invoiceRemarks"
            rows="6"
            placeholder="E.g. Payment Schedule"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!invoiceRemarks"
          @click="ok()"
        >
          <span class="align-middle">Save Changes</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="email-statement-modal"
      ref="email-statement-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Email Tax Invoice to Customer"
      @ok="sendEmailStatement"
      @cancel="closeStatementModal"
    >
      <div class="">
        <b-form-group
          label="Customer Name"
          label-for="customer-name"
          class="mb-20"
        >
          <validation-provider
            #default="{ errors }"
            name="Customer Name"
            vid="customer-name"
            rules=""
          >
            <b-form-input
              id="customer-name"
              v-model="customerNameVal"
              placeholder="Lin Chai"
              class="mb-0 text-uppercase"
              readonly
              :state="(errors.length > 0) ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Email Address"
          label-for="customer-email"
          class="mb-20"
        >
          <validation-provider
            #default="{ errors }"
            name="Email Address"
            vid="customer-email"
            rules="required"
          >
            <b-form-input
              id="customer-email"
              v-model="customerEmailVal"
              placeholder="email@domain.com"
              class="mb-0"
              :readonly="hasEmailAddress ? true : false"
              :state="(errors.length > 0) ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Message"
          label-for="statement-message"
          class="mb-20"
        >
          <validation-provider
            #default="{ errors }"
            name="Message"
            vid="statement-message"
            rules="required"
          >
            <b-form-textarea
              id="statement-message"
              v-model="statementMessage"
              placeholder="Statement of Account"
              class="mb-0"
              rows="9"
              :state="(errors.length > 0) ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="!customerEmailVal"
          @click="ok()"
        >
          <feather-icon
            icon="SendIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Send</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BCard, BCardTitle, BCardText, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, VBToggle, VBTooltip, BCollapse,
  BDropdown, BDropdownItem, BImg, BFormTextarea, BLink, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import store from '@/store/index'
import moment from 'moment'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ToastificationContentCustom from '../../operation/ToastificationContentCustom.vue'
import InvoiceItems from './InvoiceItems.vue'
import Receipts from './Receipts.vue'
import ServiceRequests from './ServiceRequests.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BCollapse,
    BDropdown,
    BDropdownItem,
    BImg,
    BFormTextarea,
    BLink,
    BFormGroup,
    BFormInput,

    ValidationProvider,
    InvoiceItems,
    Receipts,
    ServiceRequests,
    AppTimeline,
    AppTimelineItem,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      invoiceDetail: {},
      // invoiceDetail: JSON.parse(localStorage.getItem('customerInvoiceDetail')),
      invoiceDetailID: '',
      invoiceDetailActivityLogs: [],
      required,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
      currentTabTitle: 'File Details',
      activeTab: 0,
      attachments: [],
      description: '',
      attachmentKey: '',
      invoiceRemarks: '',
      invoiceID: '',
      fileID: '',
      fileNo: '',
      fileType: '',
      nvsFileType: '',
      customerNameVal: '',
      customerEmailVal: '',
      hasEmailAddress: '',
      customerContactVal: '',
      statementMessage: '',
      receipts: [],
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.fetchInvoiceDetail()
    // document.title = `${this.invoiceDetail.fileNo} - ${this.invoiceDetail.fileType} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`
    // store.commit('breadcrumb/REMOVE_BREADCRUMB')
    // const newMeta = {
    //   active: true,
    //   text: `${this.invoiceDetail.fileNo} - ${this.invoiceDetail.fileType}`,
    // }
    // store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
    // this.invoiceDetailActivityLogs = this.invoiceDetail.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
  },
  methods: {
    getCreatedBy() {
      if (this.invoiceDetail.createdByNvs) {
        return this.invoiceDetail.createdByNvs.name
      }
      if (this.invoiceDetail.createdBy) {
        return this.invoiceDetail.createdBy
      }

      return ''
    },
    getCreatedAt() {
      if (this.invoiceDetail.createdAtNvs) {
        return this.dateFormatWithTime(this.invoiceDetail.createdAtNvs)
      }
      if (this.invoiceDetail.createdAt) {
        return this.dateFormatWithFormatNTZ(this.invoiceDetail.createdAt, 'DD/MM/YYYY, hh:mma ')
      }

      return ''
    },
    getUpdatedAt() {
      if (this.invoiceDetail.updatedAtNvs) {
        return this.dateFormatWithTime(this.invoiceDetail.updatedAtNvs)
      }
      if (this.invoiceDetail.createdBy) {
        return this.dateFormatWithFormatNTZ(this.invoiceDetail.updatedAt, 'DD/MM/YYYY, hh:mma ')
      }

      return ''
    },
    fetchInvoiceDetail() {
      this.$http.get(`customer/invoices/${this.$route.params.id}/show`)
        .then(response => {
          if (response.data.invoice.trxNo) {
            this.invoiceDetail = response.data.invoice || {}
            this.receipts = response.data.receipts
            document.title = `Invoice - ${this.invoiceDetail.trxNo} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`
            // localStorage.setItem('customerInvoiceDetail', JSON.stringify(this.invoiceDetail))
            store.commit('breadcrumb/REMOVE_BREADCRUMB')
            const newMeta = {
              active: true,
              text: `Invoice Details - ${this.invoiceDetail.trxNo}`,
            }
            store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
            this.invoiceDetailActivityLogs = this.invoiceDetail.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    changeTab(index, tabName) {
      this.activeTab = index
      this.currentTabTitle = tabName
    },
    resolveInvoiceStatusVariant(status) {
      if (status === 'Paid') return 'success'
      if (status === 'Pending Payment') return 'warning'
      return 'primary'
    },
    resolveFileStatusVariant(status) {
      if (status === 'OPEN') return 'warning'
      if (status === 'BOOK') return 'info'
      return 'success'
    },
    resolvePaymentStatusVariant(status) {
      if (status === 'Fully Paid') return 'success'
      if (status === 'Ongoing Installment') return 'info'
      if (status === 'Pending Payment') return 'warning'
      if (status === 'Reactivation Fee') return 'danger'
      return 'secondary'
    },
    getVariant(key) {
      if (key % 4 === 0) return 'info'
      if (key % 4 === 1) return 'warning'
      if (key % 4 === 2) return 'danger'
      if (key % 4 === 3) return 'purple'
      return 'primary'
    },
    getResidentialAddress(purchaser) {
      if (purchaser.customer) {
        let residentialAddress = ''
        residentialAddress += purchaser.customer.perAddress1 ? purchaser.customer.perAddress1 : ''
        residentialAddress += purchaser.customer.perAddress2 ? `, ${purchaser.customer.perAddress2}` : ''
        residentialAddress += purchaser.customer.perCountry ? `, ${purchaser.customer.perCountry}` : ''
        residentialAddress += purchaser.customer.perPostcode ? ` ${purchaser.customer.perPostcode}` : ''
        return residentialAddress
      }

      return '-'
    },
    removeAttachment(name, url) {
      this.$http
        .post('customer/invoices/attachment/remove', { url, name, invoiceID: this.invoiceDetail._id })
        .then(response => {
          this.invoiceDetail.attachments = response.data.data.attachments
          this.invoiceDetailActivityLogs = response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    setAttachment(e) {
      if (e.target.files.length) {
        // const reader = new FileReader()
        // reader.readAsDataURL(this.file)
        // reader.onload = () => {
        //   e.target.filesrc = reader.result
        // }
        const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
        e.target.files.forEach(element => {
          if (acceptedTypes.includes(element.type)) {
            if (element.size > 5 * 1024 * 1024) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Can't add files more than 5mb",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unsupported file type',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
    uploadFile() {
      const formData = new FormData()
      formData.append('attachments', JSON.stringify(this.attachments))
      formData.append('invoiceID', this.$route.params.id)
      formData.append('id', this.invoiceDetail._id)
      formData.append('trxID', this.invoiceDetail.trxNo)
      formData.append('fileID', this.invoiceDetail.fileID)
      formData.append('fileNo', this.invoiceDetail.fileNo)
      formData.append('fileType', this.invoiceDetail.nvsFileID.nvsFileType)
      formData.append('nvsFileType', this.invoiceDetail.nvsFileID.nvsFileType)
      this.$http.post('customer/invoices/attachment/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.attachments = []
          this.invoiceDetail.attachments = response.data.data.attachments
          this.invoiceDetailActivityLogs = response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.invoiceDetail.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    invoiceRemarksEdit() {
      this.invoiceRemarks = this.invoiceDetail.remarks
      this.$root.$emit('bv::show::modal', 'modal-invoices-remarks', '')
    },
    saveInvoiceRemarks() {
      this.$http
        .post('customer/invoices/save/remarks', {
          remarks: this.invoiceRemarks,
          oldRemarks: this.invoiceDetail.remarks,
          invoiceID: this.$route.params.id,
          nvsInvoiceID: this.invoiceDetail._id,
          fileID: this.invoiceDetail.fileID,
          fileNo: this.invoiceDetail.fileNo,
          updatedAt: this.invoiceDetail.updatedAt,
          nvsFileType: this.invoiceDetail.nvsFileID.nvsFileType,
        })
        .then(response => {
          if (response.data.success) {
            this.invoiceDetail.remarks = this.invoiceRemarks
            this.invoiceDetailActivityLogs = response.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
            this.closeInvoiceRemarksModal()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          } else {
            this.closeInvoiceRemarksModal()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeInvoiceRemarksModal() {
      this.$root.$emit('bv::hide::modal', 'modal-invoices-remarks', '')
    },
    saveDescription() {
      const url = this.invoiceDetail.attachments[this.attachmentKey].data
      this.$http
        .post('customer/invoices/attachment/save-description', { url, description: this.description, invoiceID: this.invoiceDetail._id })
        .then(response => {
          this.invoiceDetail.attachments = response.data.data.attachments
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    downloadInvoice() {
      const formData = new FormData()
      formData.append('url', window.location.origin)
      formData.append('invoiceID', this.$route.params.id)
      formData.append('id', this.invoiceDetail._id)
      this.$http.post('customer/invoices/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `Invoice-${this.invoiceDetail.trxNo}.pdf`
          link.click()

          link.remove()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    openEmailStatementModal() {
      this.invoiceID = this.invoiceDetail.trxID
      if (this.invoiceDetail.purchasers && this.invoiceDetail.purchasers.length) {
        const firstPurchaser = this.invoiceDetail.purchasers[0]
        this.hasEmailAddress = firstPurchaser.email ? firstPurchaser.email : ''
        this.customerNameVal = firstPurchaser.name ? firstPurchaser.name : ''
        this.customerEmailVal = firstPurchaser.email ? firstPurchaser.email : ''
        this.customerContactVal = firstPurchaser.contact ? firstPurchaser.contact : ''
        this.fileType = this.invoiceDetail.nvsFileType ? this.invoiceDetail.nvsFileType : ''
        this.fileNo = this.invoiceDetail.fileNo ? this.invoiceDetail.fileNo : ''
        this.fileID = this.invoiceDetail.fileID ? this.invoiceDetail.fileID : ''
        this.statementMessage = `Here is the Tax Invoice for your purchase with us for ${this.fileType} (file no.: ${this.invoiceDetail.fileNo}), as at ${moment().format('DD MMM YYYY')}. We appreciate your business and look forward to serving you again.
      
Please be informed that the PDF is password-protected using your mobile number (e.g. 912345678).`
        this.$root.$emit('bv::show::modal', 'email-statement-modal', '')
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Purchaser Data is missing',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    closeStatementModal() {
      this.$root.$emit('bv::hide::modal', 'email-statement-modal', '')
    },
    sendEmailStatement() {
      let contact = this.customerEmailVal
      if (this.customerContactVal) {
        contact = this.customerContactVal.slice(-8)
      }
      const formData = new FormData()
      formData.append('customerName', this.customerNameVal)
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('message', this.statementMessage)
      formData.append('fileType', this.fileType)
      formData.append('fileNo', this.fileNo)
      formData.append('contact', contact)
      formData.append('fileID', this.fileID)
      formData.append('invoiceID', this.invoiceID)
      formData.append('url', `${window.location.origin}`)

      this.$http.post('customer/invoices/email/pdf', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.invoiceDetailActivityLogs = response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
          this.$toast({
            component: ToastificationContentCustom,
            props: {
              title: 'Tax Invoice Sent',
              text: response.data.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
              hideClose: true,
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style>
.timeline-variant-info .timeline-item-point {
    background-color: #00cfe8 !important;
}
.timeline-variant-purple .timeline-item-point {
    background-color: #7367F0 !important;
}
.tab-parent .nav-item {
    margin-bottom: 0.75rem !important;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
